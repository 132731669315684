<div *ngIf="transaction" class="justify-between hidden mb-2 bg-white border-b rounded-lg md:flex border-light-grey">
	<div *ngIf="isNextPayment()" class="grid grid-cols-5 w-full px-1 py-3">
		<div class="flex px-3 flex-column md:w-fit">
			<div class="flex flex-col">
				<span class="text-sm" translate="transactions.type.next-payment"></span>
				<span class="text-sm lg:text-base font-bold text-button-color">{{ transaction.estimated_date | localDate :'dd MMM yyyy' }}</span>
			</div>
		</div>
		<div class="flex justify-end px-3 flex-column md:w-fit">
			<span class="text-sm" translate="transactions.headers.payment-amount"></span>
			<span class="text-sm lg:text-base font-bold text-button-color">{{ transaction?.amount | money }}</span>
		</div>
	</div>
	<div *ngIf="isPayment()" class="grid grid-cols-5 w-full px-1 py-3">
		<div class="flex px-3 flex-column md:w-fit">
			<span class="text-sm">{{ transaction.transaction_date | localDate  : 'dd MMM yyyy' }}</span>
			<span *ngIf="isPlannedPayment()" translate="transactions.type.ag" class="text-sm lg:text-base font-bold"></span>
			<span *ngIf="isManualPayment() || isExtraAmortizationPayment()" translate="transactions.type.extra-payment" class="text-sm lg:text-base font-bold"></span>
			<span *ngIf="isMonitorImportedPayment()" translate="transactions.type.monitor-imported" class="text-sm lg:text-base font-bold"></span>
		</div>
		<div class="flex px-3 flex-column md:w-fit">
			<span class="text-sm" translate="transactions.headers.payment-amount"></span>
			<span class="text-sm lg:text-base font-bold">{{ transaction.total_amount | money:2 }}</span>
		</div>
		<div class="flex px-3 flex-column md:w-fit">
			<span class="text-sm" translate="transactions.headers.interest"></span>
			<span class="text-sm lg:text-base font-bold">{{ transaction.interest_amount| money:2 }}</span>
		</div>
		<div class="flex px-3 flex-column md:w-fit">
			<span class="text-sm" translate="transactions.headers.amortization"></span>
			<span class="text-sm lg:text-base font-bold">{{ transaction.principal_amount| money:2 }}</span>
		</div>
	</div>
	<div *ngIf="isWithdrawal()" class="grid grid-cols-5 w-full px-1 py-3">
		<div class="flex px-3 flex-column md:w-fit">
			<span class="text-sm" translate="transactions.headers.loan"></span>
			<span class="text-sm lg:text-base font-bold text-button-color">{{ transaction.transaction_date | localDate:'dd MMM yyyy' }}</span>
		</div>
		<div class="flex px-3 flex-column md:w-fit">
			<span class="text-sm" translate="transactions.headers.payment-amount"></span>
			<span class="text-sm lg:text-base font-bold text-button-color">{{ abs(transaction.principal_amount.amount) | asMoney:transaction.principal_amount.currency }}</span>
		</div>
	</div>
</div>

<div *ngIf="transaction && isNextPayment()" class="flex flex-col md:hidden text-almost-black">
	<div (click)="transaction.expanded = !transaction.expanded" [@fadeInOut]>
		<div class="flex flex-row justify-between text-almost-black">
			<div class="flex flex-column">
				<span class="text-sm font-semibold text-button-color" translate="transactions.type.next-payment"></span>
				<span class="text-sm lg:text-base">{{ transaction.estimated_date | localDate }}</span>
			</div>
			<span class="text-sm text-button-color">{{ transaction?.amount | money }}</span>
		</div>
	</div>
</div>
<div *ngIf="transaction && isPayment()" class="flex flex-col text-almost-black md:hidden" [@expandRows]="transaction.expanded ? 'expanded' : 'collapsed'">
	<div (click)="transaction.expanded = !transaction.expanded" [@fadeInOut]>
		<div class="flex flex-row justify-between">
			<div class="flex flex-column">
				<span class="text-sm font-semibold">
					<span *ngIf="isPlannedPayment()" translate="transactions.type.ag" class="text-button-color"></span>
					<span *ngIf="isManualPayment() || isExtraAmortizationPayment()" translate="transactions.type.extra-payment" class="text-button-color"></span>
					<span *ngIf="isMonitorImportedPayment()" translate="transactions.type.monitor-imported" class="text-button-color"></span>
				</span>
				<span class="text-sm text-almost-black">{{ transaction.transaction_date | localDate : 'dd MMM yyyy' }}</span>
			</div>
			<span class="text-sm text-right text-button-color">
				{{ transaction.total_amount | money:2 }}
			</span>
		</div>

		<div *ngIf="transaction.expanded" class="flex flex-row justify-between mt-2 text-almost-black expanded-row">
			<span class="text-sm font-semibold" translate="transactions.headers.interest"></span>
			<span class="text-sm text-right">
				<span>{{ transaction.interest_amount | money:2 }}</span>
			</span>
		</div>

		<div *ngIf="transaction.expanded" class="flex flex-row justify-between mt-2 text-almost-black expanded-row">
			<span class="text-sm font-semibold" translate="transactions.headers.amortization"></span>
			<span class="text-sm text-right">
				<span>{{ transaction.principal_amount | money:2 }}</span>
			</span>
		</div>

		<div *ngIf="transaction.expanded" class="flex flex-row justify-between mt-2 text-almost-black expanded-row">
			<span class="text-sm font-semibold" translate="transactions.headers.balance"></span>
			<span class="text-sm text-right">
				<span>{{ transaction.principal_balance | money:2 }}</span>
			</span>
		</div>
	</div>
</div>

<div *ngIf="transaction && isWithdrawal()" class="flex flex-col md:hidden text-almost-black" [@expandRows]="transaction.expanded ? 'expanded' : 'collapsed'">
	<div (click)="transaction.expanded = !transaction.expanded" [@fadeInOut]>
		<div class="flex flex-row justify-between text-almost-black">
			<span class="text-sm text-right">
				<span
					class="font-semibold text-button-color"
					[innerHtml]="'transactions.type.new-loan' | translate: { amount: abs(transaction.principal_amount.amount) | asMoney:transaction.principal_amount.currency }"></span>
			</span>
			<span class="text-sm ">{{ transaction.transaction_date | localDate }}</span>
		</div>

		<div *ngIf="transaction.expanded" class="flex flex-row justify-between mt-2 text-almost-black expanded-row">
			<span class="text-sm font-semibold" translate="transactions.details.balance-after"></span>
			<span class="text-sm text-right">
				<span>{{ transaction.principal_balance | money:2 }}</span>
			</span>
		</div>
	</div>
</div>
