<div
	class="px-2 py-1 text-sm font-normal leading-4 truncate rounded-full w-min"
	[ngClass]="{ 'bg-primary-400' : type === 'primary',
             'bg-black-6 ' : type === 'pending',
						 'bg-system-green ' : type === 'success',
             'bg-system-red ' : type === 'error',
             'bg-system-yellow ' : type === 'alert',
						 'text-white' : type !== 'pending',
						 'text-black' : type === 'pending' }">
	<ng-content></ng-content>
</div>
