<div
	class="h-12 w-full flex justify-between items-center border-b min-w-[100px] pr-4 md:pr-0 lg:hover:border-black cursor-pointer {{ isChecked() ? (collapsible ? 'border-none' : selectedBlackBorder ? 'border-black' : 'border-black-6') : 'border-black-6' }}"
	[ngClass]="{ 'pointer-events-none opacity-50 cursor-not-allowed' : disabled }"
	(click)="onChange()"
	(blur)="onTouched()">
	<label class="flex items-center gap-2 cursor-pointer">
		<div class="relative flex items-center p-0.5 w-5 h-5">
			<input
				type="radio"
				[checked]="isChecked()"
				[disabled]="disabled"
				class="transition-colors duration-300 outline-none border-medium-grey border-1 focus:ring-white checked:bg-white form-radio text-radio-500 checked:focus:bg-white checked:focus:border-1 checked:focus:border-black checked:hover:bg-white checked:hover:border-1 checked:hover:border-black hover:cursor-pointer checked:border-black "
				[value]="value" />
			<span *ngIf="isChecked()" class="w-2 h-2 absolute bg-black top-[6px] right-[6px] rounded-full"></span>
		</div>
		<span *ngIf="!collapsible" class="font-medium leading-none text-almost-black">
			<ng-content select="[label]"></ng-content>
		</span>
		<span *ngIf="text" class="font-medium leading-none text-almost-black">
			{{text}}
		</span>
	</label>
	<div *ngIf="description" class="pr-4 lg:pr-0 text-black-60">{{ description }}</div>
	<app-svg-icon *ngIf="collapsible && !isChecked()" [iconName]="'chevron-down.svg'" [iconId]="'chevron-down'" [style.color]="'#777777'"></app-svg-icon>
</div>
<div *ngIf="isChecked() && collapsible && !hideBorder" class="border-b pl-7 border-black-6" select="[body]">
	<div class="pt-2 mr-7 lg:mr-0">
		<ng-content select="[body]"></ng-content>
	</div>
</div>
